<template>
    <div class="space-y-2">
        <label class="text-sm font-thin block pl-1">{{ `${paymentMethod.title} Action` }}</label>

        <select class="input-primary bg-gray-100" v-model="paymentMethod.configs.action">
            <option value="pay">{{ $t('payments.pay') }}</option>
            <option value="authorize">{{ $t('payments.authorize') }}</option>
        </select>

        <p class="text-xs text-gray-700">{{ $t('payments.pay_action_description') }}</p>
    </div>

    <div class="space-y-2">
        <label class="text-sm font-thin block pl-1">{{ $t('payments.customer_type') }}</label>

        <select class="input-primary bg-gray-100" v-model="paymentMethod.configs.customer_type">
            <option :label="$t('payments.both')" value="both">{{ $t('payments.both') }}</option>
            <option :label="$t('payments.b2b')" value="b2b">{{ $t('payments.b2b') }}</option>
            <option :label="$t('payments.b2c')" value="b2c">{{ $t('payments.b2c') }}</option>
        </select>

        <p class="text-xs text-gray-700">{{ $t('payments.customer_type_description') }}</p>
    </div>

    <div class="space-y-2">
        <label class="text-sm font-thin block pl-1">{{ $t('payments.b2b_min_order') }}</label>

        <input
            type="text"
            class="input-primary bg-gray-100"
            :placeholder="$t('payments.b2b_min_order')"
            v-model="paymentMethod.configs.b2b_min_amount"
        />
        <p class="text-xs text-gray-700">{{ $t('payments.b2b_min_order_description') }}</p>
    </div>

    <div class="space-y-2">
        <label class="text-sm font-thin block pl-1">{{ $t('payments.b2b_max_order') }}</label>

        <input
            type="text"
            class="input-primary bg-gray-100"
            :placeholder="$t('payments.b2b_max_order')"
            v-model="paymentMethod.configs.b2b_max_amount"
        />
        <p class="text-xs text-gray-700">{{ $t('payments.b2b_max_order_description') }}</p>
    </div>

    <div class="space-y-2">
        <label class="text-sm font-thin block pl-1">{{ $t('payments.consumer_financial_warning') }}</label>

        <select class="input-primary bg-gray-100" v-model="paymentMethod.configs.consumer_financial_warning">
            <option :value="1">{{ $t('dashboard.yes') }}</option>
            <option :value="0">{{ $t('dashboard.no') }}</option>
        </select>

        <p class="text-xs text-gray-700">{{ $t('payments.consumer_financial_warning_description') }}</p>
    </div>
</template>

<script setup>
import { inject } from 'vue';

const paymentMethod = inject('paymentMethod');
</script>
