<template>
    <div class="p-5">
        <div class="divide-y bg-white rounded-2xl">
            <div class="rounded-t-2xl flex items-center justify-between p-5 sticky top-0 bg-white z-20 gap-8">
                <div class="flex items-center space-x-5">
                    <img class="w-16 h-12" :src="getPaymentImagePath(paymentMethod.icon)" />
                    <div class="space-y-1">
                        <h1 class="text-2xl font-bold">{{ paymentMethod.title }}</h1>
                        <p class="text-gray-500 text-sm">
                            {{ $t('payments.configure_your_settings', { title: paymentMethod.title }) }}
                        </p>
                    </div>
                </div>

                <button class="btn-primary px-6 py-3" @click="$emit('submitSetting')">
                    {{ $t('config.save') }}
                </button>
            </div>

            <div class="px-5 py-10 space-y-8">
                <div class="space-y-5">
                    <div class="space-y-2">
                        <label class="text-sm font-thin block pl-1">{{ $t('payments.mode') }}</label>

                        <PaymentMode :paymentMethod="paymentMethod" />
                        <p class="text-xs text-gray-700">{{ $t('payments.mode_description') }}</p>
                    </div>
                </div>

                <div class="space-y-2">
                    <label class="text-sm font-thin block pl-1">{{ $t('payments.frontend_label') }}</label>

                    <input
                        type="text"
                        class="input-primary bg-gray-100"
                        :placeholder="$t('payments.frontend_label')"
                        v-model="paymentMethod.configs.title"
                    />
                    <p class="text-xs text-gray-700">{{ $t('payments.front_label_description') }}</p>
                </div>

                <div class="space-y-2">
                    <label class="text-sm font-thin block pl-1">{{ $t('payments.enabled_countries') }}</label>
                    <SelectWithMultipleOptions
                        v-model="paymentMethod.configs.enabled_countries"
                        :options="countries"
                    ></SelectWithMultipleOptions>
                    <p class="text-xs text-gray-700">{{ $t('payments.enabled_countries_description') }}</p>
                </div>

                <div class="space-y-2">
                    <label class="text-sm font-thin block pl-1">{{ $t('payments.payment_fee') }}</label>

                    <input
                        type="text"
                        class="input-primary bg-gray-100"
                        :placeholder="$t('payments.payment_fee')"
                        v-model="paymentMethod.configs.payment_fee_incl"
                    />
                    <p class="text-xs text-gray-700">{{ $t('payments.payment_fee_description') }}</p>
                </div>

                <div class="space-y-2">
                    <label class="text-sm font-thin block pl-1">{{ $t('payments.minimum_order') }}</label>

                    <input
                        type="text"
                        class="input-primary bg-gray-100"
                        :placeholder="$t('payments.minimum_order')"
                        v-model="paymentMethod.configs.min_amount"
                    />
                    <p class="text-xs text-gray-700">{{ $t('payments.minimum_order_description') }}</p>
                </div>

                <div class="space-y-2">
                    <label class="text-sm font-thin block pl-1">{{ $t('payments.maximum_order') }}</label>

                    <input
                        type="text"
                        class="input-primary bg-gray-100"
                        :placeholder="$t('payments.maximum_order')"
                        v-model="paymentMethod.configs.max_amount"
                    />
                    <p class="text-xs text-gray-700">{{ $t('payments.maximum_order_description') }}</p>
                </div>

                <slot></slot>
            </div>

            <div class="p-5">
                <a type="click" href="/payments" class="btn-default inline-block">
                    {{ $t('config.go_back') }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { inject } from 'vue';
import { getPaymentImagePath } from '@js/utils/get-image-url.js';
import PaymentMode from '@js/components/PaymentMode.vue';
import { useCountries } from '@js/utils/countries';
import SelectWithMultipleOptions from '@js/components/SelectWithMultipleOptions.vue';

export default {
    props: [],
    components: {
        PaymentMode,
        SelectWithMultipleOptions,
    },
    setup(props, { emit }) {
        const paymentMethod = inject('paymentMethod');
        const { countries } = useCountries();

        return {
            paymentMethod,
            getPaymentImagePath,
            countries,
        };
    },
};
</script>
