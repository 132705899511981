<template>
    <div
        v-bind:class="{
            fixed: showMenu,
            '-translate-x-full absolute': !showMenu,
        }"
    >
        <div class="flex justify-between items-center">
            <div class="py-8 space-y-3">
                <BuckarooLogo class="w-52" />
                <p class="text-white font-bold lowercase">
                    {{ $t('dashboard.payments_for_lightspeed') }}
                </p>
            </div>

            <button
                class="bg-white text-red-600 w-10 h-10 rounded-lg flex items-center justify-center shadow-lg lg:hidden"
                @click="toggleMenu"
            >
                <i class="fa-solid fa-xmark"></i>
            </button>
        </div>

        <div class="space-y-5">
            <LanguageSelector />

            <div v-if="user" class="justify-between items-center text-left">
                <div class="bg-white bg-opacity-10 rounded-lg py-8 px-4">
                    <p class="text-sm">{{ $t('dashboard.shop') }}</p>
                    <p class="text-lg font-bold">{{ user.shop.shop_name }}</p>
                    <p class="font-normal base-sm text-sm">{{ user.shop.shop_id }}</p>
                </div>
            </div>
        </div>

        <ul class="space-y-10">
            <li>
                <a
                    v-if="!user?.has_credentials"
                    :href="pages.dashboard.welcome.path"
                    class="font-medium flex items-center space-x-3 hover:text-buckarooGreen transition ease-in-out duration-300"
                    v-bind:class="{
                        'text-buckarooGreen': routeName === 'welcome',
                        'hover:text-buckarooGreen': routeName !== 'welcome',
                    }"
                >
                    <i class="fa-solid fa-house w-5"></i>
                    <span class="block">{{ $t('dashboard.getting_started') }}</span>
                </a>
            </li>
            <li>
                <a
                    :href="pages.dashboard.settings.path"
                    class="font-medium flex items-center space-x-3 hover:text-buckarooGreen transition ease-in-out duration-300"
                    v-bind:class="{
                        'text-buckarooGreen': routeName === 'settings',
                        'hover:text-buckarooGreen': routeName !== 'settings',
                    }"
                >
                    <i class="fa-solid fa-gear w-5"></i>
                    <span class="block">{{ $t('dashboard.settings') }}</span>
                </a>
            </li>
            <li>
                <a
                    :href="pages.dashboard.paymentMethods.path"
                    class="font-medium flex items-center space-x-3 hover:text-buckarooGreen transition ease-in-out duration-300"
                    v-bind:class="{
                        'text-buckarooGreen': ['payments', 'payment-config'].includes(routeName),
                        'hover:text-buckarooGreen': !['payments', 'payment-config'].includes(routeName),
                    }"
                >
                    <i class="fa-solid fa-credit-card w-5"></i>
                    <span class="block">{{ $t('dashboard.payment_methods') }}</span>
                </a>
            </li>
            <li>
                <a
                    :href="pages.dashboard.paymentMethodsOrder.path"
                    class="font-medium flex items-center space-x-3 hover:text-buckarooGreen transition ease-in-out duration-300"
                    v-bind:class="{
                        'text-buckarooGreen': routeName === 'payment-order',
                        'hover:text-buckarooGreen': routeName !== 'payment-order',
                    }"
                >
                    <i class="fa-solid fa-arrow-right-arrow-left w-5"></i>
                    <span class="block">{{ $t('dashboard.order_payment_methods') }}</span>
                </a>
            </li>
            <li>
                <a
                    :href="pages.dashboard.verificationMethods.path"
                    class="font-medium flex items-center space-x-3 hover:text-buckarooGreen transition ease-in-out duration-300"
                    v-bind:class="{
                        'text-buckarooGreen': routeName === 'verification-methods',
                        'hover:text-buckarooGreen': routeName !== 'verification-methods',
                    }"
                >
                    <i class="fa-solid fa-check w-5"></i>
                    <span class="block">{{ $t('dashboard.verification_methods') }}</span>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
import LanguageSelector from '@js/components/LanguageSelector.vue';
import pages from '@js/utils/pages.js';
import BuckarooLogo from '@images/buckaroo_green.svg';
import { ref } from 'vue';
import { useUser } from '@js/utils/user';

export default {
    props: ['route_name'],
    components: {
        BuckarooLogo,
        LanguageSelector,
    },
    setup(props) {
        const routeName = ref(props.route_name);
        const { user } = useUser();
        const showMenu = ref(false);

        const toggleMenu = () => (showMenu.value = !showMenu.value);

        return {
            routeName,
            pages,
            user,
            showMenu,
            toggleMenu,
        };
    },
};
</script>
