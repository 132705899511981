<template>
    <div class="flex flex-col w-full items-center justify-between bg-gray-50 py-8 rounded-xl relative">
        <div class="flex flex-col items-center">
            <div class="relative">
                <img
                    :src="customImage ?? getPaymentImagePath(payment.icon)"
                    class="lg:w-24 lg:h-16 w-28 h-28 mb-4"
                    v-bind:class="{ 'filter grayscale': payment.configs.enabled == 2 }"
                    :alt="title"
                />

                <div
                    v-if="payment.configs.enabled == '1' || payment.configs.enabled == '0'"
                    class="absolute lg:-top-2.5 lg:-right-1.5 top-0 -right-3 rounded-1/2 bg-bg w-fit p-1.5"
                ></div>
            </div>

            <button class="font-regular text-center">
                <p class="base-sm font-normal text-black mb-4">
                    {{ payment.title }}
                </p>
            </button>
        </div>

        <div class="px-4 flex w-full justify-center mb-5">
            <PaymentMode v-if="payment.children?.length === 0" :paymentMethod="payment" />
        </div>

        <div v-if="hasNoConfig === undefined || !hasNoConfig">
            <button
                v-if="payment.children?.length > 0"
                class="underline font-medium"
                @click="$emit('openPaymentModal')"
            >
                Configure
            </button>

            <a v-else :href="`/payments/${payment.name}`" class="underline font-medium">Configure</a>
        </div>
    </div>
</template>

<script>
import PaymentMode from '@js/components/PaymentMode.vue';
import { getPaymentImagePath } from '@js/utils/get-image-url.js';

export default {
    props: ['payment', 'hasNoConfig', 'customImage'],
    components: {
        PaymentMode,
    },
    setup(props, { emit }) {
        return {
            getPaymentImagePath,
        };
    },
};
</script>
