<template>
    <div class="space-y-2">
        <label class="text-sm font-thin block pl-1">{{ $t('payments.in3_version') }}</label>

        <select class="input-primary bg-gray-100" v-model="paymentMethod.configs.version">
            <option
                v-for="option in [
                    { value: '0', label: $t('payments.in3') },
                    { value: '1', label: $t('payments.in2') },
                ]"
                :key="option.value"
                :value="option.value"
            >
                {{ option.label }}
            </option>
        </select>
    </div>
    <div class="space-y-2">
        <label class="text-sm font-thin block pl-1">{{ $t('payments.consumer_financial_warning') }}</label>

        <select class="input-primary bg-gray-100" v-model="paymentMethod.configs.consumer_financial_warning">
            <option :value="1">{{ $t('dashboard.yes') }}</option>
            <option :value="0">{{ $t('dashboard.no') }}</option>
        </select>

        <p class="text-xs text-gray-700">{{ $t('payments.consumer_financial_warning_description') }}</p>
    </div>
</template>

<script setup>
import { inject, watch } from 'vue';

const paymentMethod = inject('paymentMethod');

const frontendLabelOptions = {
    0: 'iDEAL In3',
    1: 'Capayable In3',
};

const iconOptions = {
    0: 'in3.svg',
    1: 'in3-v2.svg',
};

watch(
    () => paymentMethod.value.configs.version,
    newValue => {
        paymentMethod.value.configs.frontend_label = frontendLabelOptions[newValue];
        paymentMethod.value.icon = iconOptions[newValue];
    }
);
</script>
