<template>
    <div class="space-y-2">
        <label class="text-sm font-thin block pl-1">{{ $t('payments.send_instruction_email') }}</label>

        <select class="input-primary bg-gray-100" v-model="paymentMethod.configs.send_email">
            <option :value="1">{{ $t('dashboard.yes') }}</option>
            <option :value="0">{{ $t('dashboard.no') }}</option>
        </select>
    </div>

    <div class="space-y-2">
        <label class="text-sm font-thin block pl-1">{{ $t('payments.due_date') }}</label>

        <input
            type="number"
            class="input-primary bg-gray-100"
            :placeholder="$t('payments.due_date')"
            v-model="paymentMethod.configs.due_date"
        />
    </div>

    <div class="space-y-2">
        <label class="text-sm font-thin block pl-1">{{ $t('payments.change_language') }}</label>

        <select class="input-primary bg-gray-100" v-model="paymentMethod.configs.language">
            <option :value="'en'">{{ $t('payments.english') }}</option>
            <option :value="'nl'">{{ $t('payments.dutch') }}</option>
            <option :value="'fr'">{{ $t('payments.french') }}</option>
            <option :value="'de'">{{ $t('payments.german') }}</option>
        </select>
    </div>
</template>

<script setup>
import { inject } from 'vue';

const paymentMethod = inject('paymentMethod');
</script>
