<template>
    <AuthBlock>
        <div class="space-y-8">
            <div class="space-y-2">
                <label class="text-md font-thin block pl-1">{{ $t('auth.email') }}</label>
                <input type="email" class="input-primary" :placeholder="$t('auth.email')" v-model="data.email" />
            </div>

            <loading v-if="loading" />
            <button v-else class="btn-primary font-bold w-full" @click="handleSubmit">
                {{ $t('auth.send') }}
            </button>

            <div class="flex justify-center">
                <a href="/login" class="block text-primary text hover:font-bold hover:underline pt-5">
                    {{ $t('auth.back_to_login') }}
                </a>
            </div>
        </div>
    </AuthBlock>
</template>

<script>
import { useApi } from '@js/utils/api';
import useAlert from '@js/composables/useAlert.js';
import { trans } from 'laravel-vue-i18n';
import AuthBlock from '../../components/AuthBlock.vue';
import Loading from '@js/components/Loading.vue';
import endpoints from '@js/utils/endpoints';

export default {
    components: {
        AuthBlock,
        Loading,
    },
    setup() {
        const { post, data, loading } = useApi(endpoints.auth.forgotPassword, {
            email: '',
        });
        const { success, error: errorAlert } = useAlert();

        const handleSubmit = () => {
            post(data.value)
                .then(() => {
                    success(data.value.message);
                })
                .catch(err => {
                    if (err?.value?.response?.data?.message) {
                        errorAlert(err.value.response.data.message);
                    } else {
                        errorAlert(trans('alerts.unexpected_error'));
                    }
                });
        };

        return {
            data,
            handleSubmit,
            loading,
        };
    },
};
</script>
