<template>
    <div class="p-5">
        <div class="divide-y bg-white rounded-2xl">
            <div class="rounded-t-2xl flex items-center justify-between p-5 sticky top-0 bg-white gap-8">
                <div class="space-y-1">
                    <h1 class="text-2xl font-bold">{{ $t('dashboard.update_password_title') }}</h1>
                    <p class="text-gray-500 text-sm">{{ $t('dashboard.update_password_title') }}</p>
                </div>

                <div>
                    <Loading v-if="loading" />
                    <button v-else class="btn-primary px-6 py-3" @click="handleSubmit">{{ $t('config.save') }}</button>
                </div>
            </div>
            <div class="px-5 py-10 space-y-5">
                <div class="space-y-2">
                    <label class="text-md font-thin block pl-1">{{ $t('auth.current_password') }}</label>
                    <input
                        type="password"
                        class="input-primary !bg-white"
                        :placeholder="$t('auth.current_password')"
                        v-model="data.current_password"
                    />
                </div>

                <div class="space-y-2">
                    <label class="text-md font-thin block pl-1">{{ $t('auth.new_password') }}</label>
                    <input
                        type="password"
                        class="input-primary !bg-white"
                        :placeholder="$t('auth.new_password')"
                        v-model="data.new_password"
                    />
                </div>

                <div class="space-y-2">
                    <label class="text-md font-thin block pl-1">{{ $t('auth.password_confirmation') }}</label>
                    <input
                        type="password"
                        class="input-primary !bg-white"
                        :placeholder="$t('auth.password_confirmation')"
                        v-model="data.new_password_confirmation"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { trans } from 'laravel-vue-i18n';
import endpoints from '@js/utils/endpoints.js';
import useAlert from '@js/composables/useAlert';
import { useApi } from '@js/utils/api';
import Loading from '@js/components/Loading.vue';

const { success, error } = useAlert();

const { put, data, loading } = useApi(endpoints.user.updatePassword, {
    current_password: '',
    new_password: '',
    new_password_confirmation: '',
});

async function handleSubmit() {
    const { current_password, new_password, new_password_confirmation } = data.value;

    put({
        current_password,
        new_password,
        new_password_confirmation,
    })
        .then(() => {
            success(trans('alerts.password_updated'));
        })
        .catch(err => {
            if (err?.response?.data?.message) return error(err.response.data.message);
            error(trans('alerts.password_update_failed'));
        });
}
</script>
