export const SELECTED_LANGUAGE = 'selectedLanguage';
export const SELECTED_SHOP_ID = 'selectedShopID';

export const ALLOWED_GIFTCARD_METHODS = [
  {key: 'ideal', value: 'ideal'},
  {key: 'boekenbon', value: 'boekenbon'},
  {key: 'digitalebioscoopbon', value: "digitalebioscoopbon" },
  {key: 'fashioncheque', value: 'fashioncheque'},
  {key: 'ippies', value: 'ippies'},
  {key: 'nationaleentertainmentcard', value: 'nationaleentertainmentcard'},
  {key: 'podiumcadeaukaart', value: 'podiumcadeaukaart'},
  {key: 'vvvgiftcard', value: 'vvvgiftcard'},
  {key: 'webshopgiftcard', value: 'webshopgiftcard'},
  {key: 'yourgift', value: 'yourgift'},
];

export const getBuckarooInstance = () => {
  return {
    selectedShopID: window.BuckarooApp.selectedShopID,
    selectedLanguage: window.BuckarooApp.selectedLanguage,
    availableCountries: window.BuckarooApp.availableCountries
  }
}
