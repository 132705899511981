<template>
    <div class="bg-white rounded-full text-sm ring-1 ring-black/5 h-10 relative inline-block">
        <div class="flex h-full items-center divide-x" ref="paymentModeElement">
            <button
                type="button"
                ref="liveButtonEl"
                class="z-10 h-full px-3 transition delay-75"
                @click="handleChangeMode('0')"
                v-bind:class="{
                    'text-': mode === '0',
                }"
            >
                Live
            </button>
            <button
                type="button"
                ref="testButtonEl"
                class="z-10 h-full px-3 transition delay-75"
                @click.stop="handleChangeMode('1')"
                v-bind:class="{
                    'text-': mode === '1',
                }"
            >
                Test
            </button>
            <button
                type="button"
                ref="offButtonEl"
                class="z-10 h-full px-3 transition delay-75"
                @click.stop="handleChangeMode('2')"
                v-bind:class="{
                    'text-': mode === '2',
                }"
            >
                Off
            </button>
        </div>

        <div
            class="h-full absolute left-0 z-0 transition ease-in-out duration-300 top-0"
            v-bind:style="{
                transform: `translate(${position}px)`,
            }"
        >
            <div
                class="h-full rounded-lg p-1"
                v-bind:style="{
                    width: `${sliderWidth}px`,
                }"
                v-bind:class="{
                    'bg-signalStatusOk rounded-l-full': mode === '0',
                    'bg-signalStatusInfo rounded-none': mode === '1',
                    'bg-gray-300 rounded-r-full': mode === '2',
                }"
            ></div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useApi } from '@js/utils/api';
import useAlert from '@js/composables/useAlert.js';
import { trans } from 'laravel-vue-i18n';

export default {
    props: ['paymentMethod', 'active'],
    components: {},
    setup(props, { emit }) {
        const mode = ref(props.paymentMethod.configs.enabled);
        const position = ref(0);
        const sliderWidth = ref(0);
        const paymentModeElement = ref(null);
        const liveButtonEl = ref(null);
        const testButtonEl = ref(null);
        const offButtonEl = ref(null);
        const { put, data } = useApi(`payment/${props.paymentMethod.name}/settings`);
        const { success, error } = useAlert();

        const handleChangeMode = m => {
            mode.value = m;

            setSliderLocation();

            handlePaymentModeChange();
        };

        const setSliderLocation = () => {
            let button = null;

            switch (mode.value) {
                case '0':
                    button = liveButtonEl.value.getBoundingClientRect();
                    break;
                case '1':
                    button = testButtonEl.value.getBoundingClientRect();
                    break;
                default:
                    button = offButtonEl.value.getBoundingClientRect();
            }

            position.value = button.left - paymentModeElement.value.getBoundingClientRect().left;
            sliderWidth.value = button.width;
        };

        const handlePaymentModeChange = () => {
            if (props.paymentMethod.configs.enabled != mode.value) {
                props.paymentMethod.configs.enabled = mode.value;

                put({
                    enabled: mode.value,
                })
                    .then(() => {
                        if (data.value.status) {
                            success(trans('alerts.payment_mode_updated'));
                        }
                    })
                    .catch(() => {
                        error(trans('alerts.unexpected_error'));
                    });
            }
        };

        return {
            mode,
            handleChangeMode,
            position,
            paymentModeElement,
            sliderWidth,
            liveButtonEl,
            testButtonEl,
            offButtonEl,
            setSliderLocation,
        };
    },
    mounted() {
        this.setSliderLocation();
    },
};
</script>
