import { ref } from 'vue'
import { useApi } from './api'
import endpoints from './endpoints.js';

const { get, data } = useApi(endpoints.auth.me)

const user = ref(null)

get().then(() => {
    if(data.value.status) {
        user.value = data.value.user
    }
})

export const useUser = () => {

    return {
        user
    }
}
