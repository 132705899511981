<template>
    <div>
        <dialog class="block relative w-full z-50 p-0" v-if="showModal">
            <div
                class="md:p-7 flex justify-center items-start overflow-x-hidden overflow-y-auto fixed left-0 top-0 w-full h-full bg-gray-900 bg-opacity-50 z-50"
                @click.self="closeModal"
            >
                <div class="flex 2xl:w-1/3 xl:w-1/2 md:w-2/3 w-full relative items-center">
                    <div class="bg-white w-full md:rounded-xl shadow-xl relative z-10">
                        <div class="md:px-5 md:py-5 p-3 flex items-center w-full border-b">
                            <div class="text-center w-full space-y-1">
                                <div class="text-center text-lg font-bold">
                                    <span>{{ payment.title }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="px-5 py-10 grid grid-cols-3 gap-4">
                            <PaymentCard
                                v-for="paymentMethod in payment.children"
                                :key="paymentMethod.name"
                                :payment="paymentMethod"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </dialog>
    </div>
</template>

<script>
import { ref } from 'vue';
import PaymentCard from '@js/components/PaymentCard.vue';

export default {
    props: [],
    components: {
        PaymentCard,
    },
    setup(props, { emit }) {
        const showModal = ref(false);
        const payment = ref(null);

        const openModal = p => {
            if (p.children.length) {
                payment.value = p;

                showModal.value = true;
            }
        };

        const closeModal = () => {
            showModal.value = false;
        };

        return {
            showModal,
            openModal,
            payment,
            closeModal,
        };
    },
};
</script>
