<template>
    <div class="p-5">
        <div class="divide-y bg-white rounded-2xl">
            <div class="rounded-t-2xl flex items-center justify-between p-5 bg-white">
                <div class="space-y-1">
                    <h1 class="text-2xl font-bold">{{ $t('dashboard.verification_methods_title') }}</h1>
                    <p class="text-gray-500 text-sm">{{ $t('dashboard.verification_methods_description') }}</p>
                </div>
            </div>
            <div class="px-5 py-10 grid xl:grid-cols-5 lg:grid-cols-3 grid-cols-2 gap-4">
                <PaymentCard
                    :key="iDIN"
                    :payment="iDIN"
                    :custom-image="getImageUrl('idin_logo.svg')"
                    :hasNoConfig="true"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import PaymentCard from '@js/components/PaymentCard.vue';
import { getImageUrl } from '@js/utils/get-image-url';

const { idinConfig } = defineProps({
    idinConfig: String,
});

const iDIN = ref({
    name: 'iDIN',
    title: 'iDIN',
    logo: getImageUrl('idin_logo.svg'),
    configs: {
        enabled: idinConfig,
    },
    children: [],
});
</script>
