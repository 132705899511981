<template>
    <div class="h-full">
        <div class="h-full flex flex-col items-center justify-center px-5">
            <div class="xl:w-2/3 w-full bg-white rounded-2xl lg:flex overflow-hidden">
                <div class="xl:w-1/2 lg:w-2/5 lg:h-auto h-96 w-full">
                    <iframe
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                        class="h-full"
                        src="https://www.youtube.com/embed/IGF8wWyVc1Y"
                        width="100%"
                    />
                </div>

                <div class="xl:w-1/2 lg:w-3/5 w-full p-8 space-y-8 text-gray-700">
                    <h2 class="text-3xl font-semibold text-black">{{ $t('dashboard.getting_started') }}</h2>

                    <p class="">{{ $t('dashboard.explanation_intro') }}</p>

                    <ul class="space-y-5">
                        <li class="flex space-x-2 items-center">
                            <div class="w-8 h-8 rounded-full border border-black flex items-center justify-center">
                                1
                            </div>
                            <span class="block">Fill in your</span>
                            <a href="/settings" class="bg-warmGrey rounded-full px-5 py-2 text-black">
                                {{ $t('dashboard.storekey') }}
                            </a>
                        </li>

                        <li class="flex space-x-2 items-center">
                            <div class="w-8 h-8 rounded-full border border-black flex items-center justify-center">
                                2
                            </div>
                            <span class="block">Fill in your</span>
                            <a href="#" class="bg-warmGrey rounded-full px-5 py-2 text-black">
                                {{ $t('dashboard.secretkey') }}
                            </a>
                        </li>

                        <li class="flex space-x-2 items-center">
                            <div class="w-8 h-8 rounded-full border border-black flex items-center justify-center">
                                3
                            </div>
                            <span class="block">{{ $t('dashboard.step_three') }}</span>
                        </li>

                        <li class="flex space-x-2 items-center">
                            <div class="w-8 h-8 rounded-full border border-black flex items-center justify-center">
                                4
                            </div>
                            <span class="block">{{ $t('dashboard.step_four') }}</span>
                        </li>

                        <li class="flex space-x-2 items-center">
                            <div class="w-8 h-8 rounded-full border border-black flex items-center justify-center">
                                5
                            </div>
                            <span class="block">{{ $t('dashboard.step_five') }}</span>
                        </li>
                    </ul>

                    <p class="base-sm">
                        {{ $t('dashboard.if_you_have_any_questions') }}
                        <a class="font-semibold underline" href="mailto:support@buckaroo.nl" target="_top">
                            support@buckaroo.nl
                        </a>
                        {{ $t('dashboard.or_by_phone') }}
                        <a class="font-semibold underline" href="tel:+31307115020" target="_top">
                            +31 (0) 30 711 50 20
                        </a>
                    </p>

                    <a class="inline-block btn-primary py-4 px-5" href="/settings">
                        {{ $t('dashboard.set_credentials') }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
