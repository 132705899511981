<template>
    <div class="space-y-2">
        <label class="text-sm font-thin block pl-1">{{ $t('payments.allowed_giftcard_methods') }}</label>
        <SelectWithMultipleOptions
            v-model="paymentMethod.configs.allowed_methods"
            :options="ALLOWED_GIFTCARD_METHODS"
        />
    </div>
    <div class="space-y-2">
        <label class="text-sm font-thin block pl-1">{{ $t('payments.add_giftcard_method_list') }}</label>
        <SelectWithMultipleOptions v-model="paymentMethod.configs.extra_methods" :options="countries" />
        <p class="text-xs text-gray-700">{{ $t('payments.add_giftcard_method_list_description') }}</p>
    </div>
</template>

<script setup>
import { inject } from 'vue';
import SelectWithMultipleOptions from '@js/components/SelectWithMultipleOptions.vue';
import { ALLOWED_GIFTCARD_METHODS } from '@js/utils/constants.js';
import { useCountries } from '@js/utils/countries';

const paymentMethod = inject('paymentMethod');

const { countries } = useCountries();
</script>
