<template>
    <div class="p-5">
        <div class="divide-y bg-white rounded-2xl">
            <div class="rounded-t-2xl flex items-center justify-between p-5 sticky top-0 bg-white gap-8">
                <div class="space-y-1">
                    <h1 class="text-2xl font-bold">{{ $t('config.title') }}</h1>
                    <p class="text-gray-500 text-sm">{{ $t('config.description') }}</p>
                </div>

                <div>
                    <loading v-if="loading" />
                    <button v-else class="btn-primary px-6 py-3" @click="handleSubmit">{{ $t('config.save') }}</button>
                </div>
            </div>

            <div class="px-5 py-10 space-y-5">
                <div class="flex items-center justify-between">
                    <div class="space-y-1">
                        <h2 class="text-lg font-bold">{{ $t('config.credentials') }}</h2>
                        <p class="text-gray-500 text-sm" v-html="$t('config.get_your_keys')"></p>
                    </div>

                    <button
                        @click="handleTestCredentials"
                        class="border border-primary rounded-full px-6 py-3 hover:bg-primary hover:text-white transition-colors ease-in-out duration-300"
                        v-bind:class="{
                            'opacity-30 cursor-not-allowed': !settings.website_key || !settings.secret_key,
                        }"
                    >
                        {{ $t('config.test_connection_button') }}
                    </button>
                </div>

                <div class="space-y-5">
                    <div class="space-y-2">
                        <label class="text-sm font-thin block pl-1">{{ $t('config.store_key_label') }}</label>

                        <input
                            type="password"
                            class="input-primary bg-gray-100"
                            :placeholder="$t('config.store_key_label')"
                            v-model="settings.website_key"
                        />
                    </div>

                    <div class="space-y-2">
                        <label class="text-sm font-thin block pl-1">{{ $t('config.secret_key_label') }}</label>

                        <input
                            type="password"
                            class="input-primary bg-gray-100"
                            :placeholder="$t('config.secret_key_label')"
                            v-model="settings.secret_key"
                        />
                    </div>
                </div>
            </div>

            <div class="px-5 py-10 space-y-5">
                <div>
                    <div class="space-y-1">
                        <h2 class="text-lg font-bold">{{ $t('config.descriptions') }}</h2>
                        <p class="text-gray-500 text-sm" v-html="$t('config.descriptions_info')"></p>
                    </div>
                </div>

                <div class="space-y-5">
                    <div class="space-y-2">
                        <label class="text-sm font-thin block pl-1">
                            {{ $t('config.transaction_description_label') }}
                        </label>

                        <input
                            type="text"
                            class="input-primary bg-gray-100"
                            :placeholder="$t('config.transaction_description_label')"
                            v-model="settings.transaction_description"
                        />
                        <p class="text-xs text-gray-700">{{ $t('config.refund_description_tooltip') }}</p>
                    </div>

                    <div class="space-y-2">
                        <label class="text-sm font-thin block pl-1">{{ $t('config.refund_description_label') }}</label>

                        <input
                            type="text"
                            class="input-primary bg-gray-100"
                            :placeholder="$t('config.refund_description_label')"
                            v-model="settings.refund_description"
                        />
                        <p class="text-xs text-gray-700">{{ $t('config.refund_description_tooltip') }}</p>
                    </div>
                </div>
            </div>

            <div class="px-5 py-10 space-y-5">
                <div>
                    <div class="space-y-1">
                        <h2 class="text-lg font-bold">{{ $t('config.extras') }}</h2>
                    </div>
                </div>

                <div class="space-y-5">
                    <div class="space-y-2">
                        <label class="text-sm font-thin block pl-1">{{ $t('config.shop_image_label') }}</label>

                        <input
                            type="text"
                            class="input-primary bg-gray-100"
                            :placeholder="$t('config.shop_image_label')"
                            v-model="settings.shop_image"
                        />
                        <p class="text-xs text-gray-700">{{ $t('config.shop_image_tooltip') }}</p>
                    </div>

                    <div class="space-y-2">
                        <label class="text-sm font-thin block pl-1">{{ $t('config.refund_enabled_label') }}</label>

                        <select class="input-primary bg-gray-100" v-model="settings.refund_enabled">
                            <option value="1">{{ $t('config.refund_enabled_option_one') }}</option>
                            <option value="0">{{ $t('config.refund_enabled_option_two') }}</option>
                        </select>

                        <p class="text-xs text-gray-700">{{ $t('config.refund_enabled_tooltip') }}</p>
                    </div>

                    <div class="space-y-2">
                        <label class="text-sm font-thin block pl-1">
                            {{ $t('config.cancel_expired_period_label') }}
                        </label>

                        <select class="input-primary bg-gray-100" v-model="settings.cancel_expired_period">
                            <option
                                v-for="option in [
                                    { label: $t('config.cancel_expired_period_option_one'), value: '60' },
                                    { label: $t('config.cancel_expired_period_option_two'), value: '480' },
                                    { label: $t('config.cancel_expired_period_option_three'), value: '1440' },
                                    { label: $t('config.cancel_expired_period_option_four'), value: '2880' },
                                    { label: $t('config.cancel_expired_period_option_five'), value: '10080' },
                                    { label: $t('config.cancel_expired_period_option_six'), value: '43200' },
                                    { label: $t('config.cancel_expired_period_option_seven'), value: '525600' },
                                ]"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value"
                            >
                                {{ $t('config.refund_enabled_option_one') }}
                            </option>
                        </select>

                        <p class="text-xs text-gray-700">{{ $t('config.cancel_expired_period_tooltip') }}</p>
                    </div>

                    <div class="space-y-2">
                        <label class="text-sm font-thin block pl-1">{{ $t('config.script_url_label') }}</label>

                        <input
                            type="text"
                            class="input-primary bg-gray-100"
                            :placeholder="$t('config.script_url_label')"
                            :value="scriptUrl"
                            disabled
                        />
                        <p class="text-xs text-gray-700">{{ $t('config.script_url_tooltip') }}</p>
                    </div>

                    <div class="space-y-2">
                        <label class="text-sm font-thin block pl-1">{{ $t('config.icons_enabled_label') }}</label>

                        <select class="input-primary bg-gray-100" v-model="settings.icons_enabled">
                            <option
                                v-for="option in [
                                    { label: $t('config.icons_enabled_option_one'), value: '1' },
                                    { label: $t('config.icons_enabled_option_two'), value: '0' },
                                ]"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value"
                            ></option>
                        </select>
                        <p class="text-xs text-gray-700">{{ $t('config.icons_enabled_tooltip') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed } from 'vue';
import useAlert from '@js/composables/useAlert.js';
import { useApi } from '@js/utils/api';
import { trans } from 'laravel-vue-i18n';

export default {
    props: ['settings'],
    components: {},
    setup(props) {
        const { put, data, loading } = useApi('settings');
        const { get, data: testCredentialsData, error: testCredentialsError } = useApi('test-credentials');
        const { success, error: errorAlert } = useAlert();

        const defaultSettings = {
            website_key: '',
            secret_key: '',
            shop_image: '',
            transaction_description: '{order_number} {shop_name}',
            refund_enabled: '0',
            refund_description: '{order_number} {shop_name}',
            cancel_expired_period: '60',
            icons_enabled: '0',
        };

        const settings = ref({ ...defaultSettings, ...props.settings });

        const scriptUrl = computed(() => `${window.location.origin}/custom-head-code.js`);

        const handleSubmit = () => {
            put({ ...settings.value })
                .then(() => {
                    if (data.value.status) {
                        success(trans('alerts.settings_updated'));
                    }
                })
                .catch(() => {
                    errorAlert(trans('alerts.settings_update_failed'));
                });
        };

        const handleTestCredentials = () => {
            get()
                .then(() => {
                    success(testCredentialsData.value.message);
                })
                .catch(() => {
                    if (testCredentialsError.value.response.message)
                        errorAlert(testCredentialsError.value.response.message);
                    errorAlert('alerts.unexpected_error');
                });
        };

        return {
            settings,
            handleSubmit,
            loading,
            handleTestCredentials,
            scriptUrl,
        };
    },
};
</script>
