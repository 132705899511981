<template>
    <AuthBlock>
        <div class="space-y-8">
            <div class="space-y-2">
                <label class="text-md font-thin block pl-1">{{ $t('auth.email') }}</label>
                <input type="text" class="input-primary" :placeholder="$t('auth.email')" v-model="form.email" />
            </div>

            <div class="space-y-2">
                <label class="text-md font-thin block pl-1">{{ $t('auth.password') }}</label>
                <input
                    type="password"
                    class="input-primary"
                    :placeholder="$t('auth.password')"
                    v-model="form.password"
                />
            </div>

            <div class="space-y-2">
                <label class="text-md font-thin block pl-1">{{ $t('auth.password_confirmation') }}</label>

                <input
                    type="password"
                    class="input-primary"
                    :placeholder="$t('auth.password_confirmation')"
                    name="password_confirmation"
                    v-model="form.password_confirmation"
                />
            </div>

            <loading v-if="loading" />
            <button v-else class="btn-primary font-bold w-full" @click="handleSubmit">{{ $t('auth.register') }}</button>

            <a href="/login" class="block text-primary text hover:font-bold hover:underline pt-5">
                {{ $t('auth.login') }}
            </a>
        </div>
    </AuthBlock>
</template>

<script>
import { ref } from 'vue';
import { useApi } from '@js/utils/api';
import pages from '@js/utils/pages.js';
import useAlert from '@js/composables/useAlert.js';
import { trans } from 'laravel-vue-i18n';
import AuthBlock from '../../components/AuthBlock.vue';
import Loading from '@js/components/Loading.vue';
import getUrlParams from '@js/utils/get-url-params';

export default {
    components: {
        AuthBlock,
        Loading,
    },
    setup() {
        const { post, data, error, loading } = useApi('/register');
        const { error: errorAlert } = useAlert();

        const shopId = getUrlParams('shop_id');

        const form = ref({
            email: '',
            password: '',
            password_confirmation: '',
            shop_id: shopId,
        });

        const handleSubmit = () => {
            post(form.value)
                .then(() => {
                    if (data.value.status) {
                        window.location.href = pages.dashboard.welcome.path;
                    }
                })
                .catch(() => {
                    if (error.value.response?.data?.message) return errorAlert(error.value.response.data.message);
                    errorAlert(trans('alerts.unexpected_error'));
                });
        };

        return {
            form,
            handleSubmit,
            loading,
        };
    },
};
</script>
