<template>
    <div class="relative bg-warmGrey flex lg:justify-end justify-between items-center py-8 px-4 z-10">
        <button class="lg:hidden flex gap-2 items-center leading-none" @click="$emit('toggle-menu')">
            <i class="fa-solid fa-bars w-4 h-4"></i>
            {{ $t('dashboard.menu') }}
        </button>

        <div class="flex">
            <button
                @click="toggleDropdown"
                class="flex items-center space-x-3 gap-2 p-2 rounded-xl text-black text-base leading-none"
            >
                <i class="fa-solid fa-gear w-4 h-4"></i>
                {{ $t('dashboard.settings') }}
            </button>
            <ul v-if="isDropdownOpen" class="absolute bg-primary -bottom-12 rounded-lg right-4">
                <li class="flex items-center px-4 py-2 cursor-pointer text-white rounded-lg">
                    <a class="hover:underline" :href="pages.dashboard.updatePassword.path">
                        {{ $t('auth.change_password') }}
                    </a>
                </li>
                <li class="flex items-center px-4 py-2 cursor-pointer text-white rounded-lg">
                    <a :href="endpoints.user.logout" class="hover:underline">
                        {{ $t('auth.logout') }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import pages from '@js/utils/pages';
import endpoints from '@js/utils/endpoints';

defineEmits(['toggle-menu']);

const isDropdownOpen = ref(false);

const toggleDropdown = () => (isDropdownOpen.value = !isDropdownOpen.value);
</script>
