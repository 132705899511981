<template>
    <div class="flex flex-col rounded-xl bg-white items-center overflow-hidden p-8 gap-4 text-gray-500">
        <slot name="logo"></slot>
        <div class="flex flex-col w-full mb-4">
            <label class="w-full mb-1">
                {{ $t('checkout.choose_birthday') }}
            </label>
            <DatePicker v-model="model.birthDate" />
        </div>

        <slot></slot>
    </div>
</template>

<script setup>
import DatePicker from '@js/components/DatePicker.vue';
const model = defineModel();
</script>
