<template>
    <div class="flex h-full justify-center items-center">
        <form class="sm:w-[400px] w-10/12" @submit.prevent="handleSubmit()">
            <div class="rounded-xl bg-white">
                <component
                    v-if="paymentMethods[serviceCode]"
                    v-model="form"
                    :is="paymentMethods[serviceCode].component"
                >
                    <template v-slot:logo>
                        <div class="w-full flex" :class="logo ? 'justify-between' : 'justify-center'">
                            <img v-if="logo" :src="logo" alt="shop logo" class="w-20 h-20" />
                            <component
                                :is="paymentMethods[serviceCode].logo"
                                :alt="serviceCode + ' logo'"
                                class="w-20 h-20"
                            ></component>
                        </div>
                    </template>

                    <div class="flex flex-col w-full justify-between">
                        <div
                            v-if="showFinancialWarning"
                            class="mb-4 text-md"
                            v-html="$t('checkout.financial_warning')"
                        />
                        <div class="flex justify-between w-full">
                            <button class="w-fit" @click="handleCancel" type="button">
                                {{ $t('checkout.cancel') }}
                            </button>
                            <button class="btn-primary font-bold w-fit px-6" type="submit">
                                {{ $t('checkout.pay') }}
                            </button>
                        </div>
                    </div>
                </component>
            </div>
        </form>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import AfterpayCheckoutConfig from '@js/components/checkout/AfterpayCheckoutConfig.vue';
import BillinkCheckoutConfig from '@js/components/checkout/BillinkCheckoutConfig.vue';
import KlarnaCheckoutConfig from '@js/components/checkout/KlarnaCheckoutConfig.vue';
import SepaCheckoutConfig from '@js/components/checkout/SepaCheckoutConfig.vue';
import In3OldCheckoutConfig from '@js/components/checkout/In3OldCheckoutConfig.vue';
import useAlert from '@js/composables/useAlert.js';
import { trans } from 'laravel-vue-i18n';
import { useApi } from '@js/utils/api';
import AfterpayLogo from '@images/svg/icon-payment-afterpaynew.svg';
import BillinkLogo from '@images/svg/icon-payment-billink.svg';
import OldIN3Logo from '@images/svg/icon-payment-in3-v2.svg';
import KlarnaLogo from '@images/svg/icon-payment-klarna.svg';
import SepaLogo from '@images/svg/icon-payment-sepa.svg';

const { logo, serviceCode, showFinancialWarning, transactionId, shopUrl, shopId } = defineProps({
    logo: String,
    serviceCode: String,
    showFinancialWarning: Boolean,
    transactionId: Number,
    shopUrl: String,
    shopId: Number,
});

const paymentMethods = {
    afterpaynew: {
        component: AfterpayCheckoutConfig,
        logo: AfterpayLogo,
    },
    billink: {
        component: BillinkCheckoutConfig,
        logo: BillinkLogo,
    },
    klarnapay: {
        component: KlarnaCheckoutConfig,
        logo: KlarnaLogo,
    },
    klarnasliceit: {
        component: KlarnaCheckoutConfig,
        logo: KlarnaLogo,
    },
    sepadirectdebit: {
        component: SepaCheckoutConfig,
        logo: SepaLogo,
    },
    capayable: {
        component: In3OldCheckoutConfig,
        logo: OldIN3Logo,
    },
};

const { error: errorAlert } = useAlert();

const form = ref({
    birthDate: null,
    gender: null,
    acceptTerms: false,
    iBAN: null,
    BIC: null,
});

const { post, data, error } = useApi('checkout');

async function handleSubmit() {
    post({
        transactionId: transactionId,
        shop_id: shopId,
        payment_method: {
            method: serviceCode,
        },
        ...form.value,
    })
        .then(() => {
            window.location.href = data.value.payment_url;
        })
        .catch(() => {
            if (error?.value.response?.data?.message) return errorAlert(error.value.response.data.message);
            errorAlert(trans('alerts.unexpected_error'));
        });
}

const handleCancel = () => (window.location.href = `//${shopUrl}`);
</script>
