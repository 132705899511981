<template>
    <AuthBlock>
        <div class="space-y-8">
            <div class="space-y-2">
                <label class="text-md font-thin block pl-1">Email</label>

                <input type="text" class="input-primary" :placeholder="$t('auth.email')" v-model="form.email" />
            </div>

            <div class="space-y-2">
                <label class="text-md font-thin block pl-1">Password</label>

                <input
                    type="password"
                    class="input-primary"
                    placeholder="Fill in your password"
                    v-model="form.password"
                />
            </div>

            <loading v-if="loading" />
            <button v-else class="btn-primary font-bold w-full" @click="handleSubmit">Log in</button>

            <div class="space-x-2 flex items-center">
                <input
                    id="rememberMe"
                    type="checkbox"
                    class="accent-buckarooGreen w-4 h-4"
                    v-model="form.remember_me"
                />
                <label for="rememberMe" class="text-sm cursor-pointer">Remember me</label>
            </div>

            <a href="/forgot-password" class="block text-primary text hover:font-bold hover:underline pt-5">
                {{ $t('auth.forgot_password') }}
            </a>
        </div>
    </AuthBlock>
</template>

<script>
import { ref } from 'vue';
import { useApi } from '@js/utils/api';
import pages from '@js/utils/pages.js';
import useAlert from '@js/composables/useAlert.js';
import { trans } from 'laravel-vue-i18n';
import AuthBlock from '../../components/AuthBlock.vue';

export default {
    components: {
        AuthBlock,
    },
    setup(props) {
        const { post, data, error, loading } = useApi('login');
        const { error: errorAlert } = useAlert();

        const form = ref({
            email: '',
            password: '',
            remember_me: false,
        });

        const handleSubmit = () => {
            post(form.value)
                .then(() => {
                    if (data.value.status) {
                        window.location.href = pages.dashboard.welcome.path;
                    }
                })
                .catch(() => {
                    if (error.value.response?.data?.message) return errorAlert(error.value.response.data.message);

                    errorAlert(trans('alerts.unexpected_error'));
                });
        };

        return {
            form,
            handleSubmit,
            loading,
        };
    },
};
</script>
