import axios, { AxiosRequestConfig } from 'axios';
import { ref } from 'vue';

export const useApi = (endpoint: string, initialData: unknown) => {
    var endPoint = endpoint;

    const api = axios.create({
        baseURL: '/api/web/v1',
    });

    const data = ref(initialData ?? null);
    const loading = ref(false);
    const error = ref();

    const setEndpoint = (endpoint: string) => {
        endPoint = endpoint;
    };

    const get = (query?: Record<string, any>, config?: AxiosRequestConfig) => {
        loading.value = true;
        error.value = undefined;

        let queryString = '';

        if (query) {
            queryString =
                '?' +
                Object.entries(query)
                    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                    .join('&');
        }

        return api
            .get(endPoint + queryString, config)
            .then(res => (data.value = res.data))
            .catch(e => {
                error.value = e;

                throw e;
            })
            .finally(() => (loading.value = false));
    };

    const post = (payload?: Record<string, any>) => {
        loading.value = true;
        error.value = undefined;

        return api
            .post(endPoint, payload)
            .then(res => (data.value = res.data))
            .catch(e => {
                error.value = e;

                throw e;
            })
            .finally(() => (loading.value = false));
    };

    const put = (payload?: Record<string, any>) => {
        loading.value = true;
        error.value = undefined;

        return api
            .put(endPoint, payload)
            .then(res => (data.value = res.data))
            .catch(e => {
                error.value = e;

                throw e;
            })
            .finally(() => (loading.value = false));
    };

    return {
        setEndpoint,
        loading,
        data,
        error,
        get,
        post,
        put,
    };
};
