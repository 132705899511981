<template>
    <div class="space-y-2">
        <label class="text-sm font-thin block pl-1">{{ $t('payments.seller_protection') }}</label>

        <select class="input-primary bg-gray-100" v-model="paymentMethod.configs.seller_protection">
            <option :value="1">{{ $t('dashboard.yes') }}</option>
            <option :value="0">{{ $t('dashboard.no') }}</option>
        </select>
        <p class="text-xs text-gray-700">{{ $t('payments.seller_protection_description') }}</p>
    </div>
</template>

<script setup>
import { inject } from 'vue';

const paymentMethod = inject('paymentMethod');
</script>
