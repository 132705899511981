<template>
    <div class="rounded-xl bg-white overflow-hidden p-8 w-3/4 space-y-8 max-w-lg">
        <div class="text-center space-y-3">
            <div class="flex justify-center">
                <BuckarooLogo class="w-44" />
            </div>
            <h2 class="font-semibold text-gray-700 text-sm">Payment for Lightspeed</h2>
        </div>
        <slot></slot>
    </div>
</template>

<script>
import BuckarooLogo from '@images/buckaroo_payoff_logo.svg';

export default {
    components: {
        BuckarooLogo,
    },
};
</script>
