<template>
    <div class="relative">
        <button @click="toggleDropdown" class="flex items-center space-x-3 text-sm p-2 rounded-xl text-white">
            <component :is="languages[currentLanguage]?.icon || UnitedKingdomLogo" class="w-5 flex-none" />
            <span class="block">{{ languages[currentLanguage]?.text || 'English' }}</span>
        </button>

        <ul v-if="isDropdownOpen" class="absolute mt-2 bg-gray-50 shadow-md border rounded-lg w-40 py-2">
            <li
                v-for="(language, code) in languages"
                :key="code"
                @click="changeLanguage(code)"
                class="flex items-center px-4 py-2 cursor-pointer text-black hover:bg-buckarooGreen"
            >
                <component :is="language.icon" class="w-5 mr-2" />
                <span class="text-sm">{{ language.text }}</span>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import FranceLogo from '@images/flags/france.svg';
import GermanyLogo from '@images/flags/germany.svg';
import NetherlandsLogo from '@images/flags/netherlands.svg';
import UnitedKingdomLogo from '@images/flags/united-kingdom.svg';
import { loadLanguageAsync, getActiveLanguage } from 'laravel-vue-i18n';
import { useApi } from '@js/utils/api';

const { post } = useApi('/session');

const isDropdownOpen = ref(false);

const currentLanguage = ref(getActiveLanguage());

const languages = {
    en: { icon: UnitedKingdomLogo, text: 'English' },
    nl: { icon: NetherlandsLogo, text: 'Nederlands' },
    de: { icon: GermanyLogo, text: 'German' },
    fr: { icon: FranceLogo, text: 'French' },
};

function toggleDropdown() {
    isDropdownOpen.value = !isDropdownOpen.value;
}

function changeLanguage(code) {
    loadLanguageAsync(code);
    isDropdownOpen.value = false;
    currentLanguage.value = code;

    post({ selectedLanguage: code });
}
</script>
