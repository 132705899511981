<template>
    <div class="flex flex-col rounded-xl bg-white items-center overflow-hidden p-8 gap-4 text-gray-500">
        <slot name="logo"></slot>

        <div class="flex flex-col w-full m-0">
            <label class="w-full mb-1">
                {{ $t('checkout.choose_birthday') }}
            </label>
            <DatePicker v-model="model.birthDate" />
        </div>

        <div class="flex justify-start w-full gap-2 mb-4">
            <input v-model="model.acceptTerms" type="checkbox" id="acceptTerms" class="accent-buckarooGreen w-4 h-4" />
            <label for="acceptTerms" v-html="$t('checkout.billink_terms')"></label>
        </div>

        <slot></slot>
    </div>
</template>

<script setup>
import DatePicker from '@js/components/DatePicker.vue';

const model = defineModel();
</script>
