import { trans } from "laravel-vue-i18n";

const pages = {
    layouts: {
        dashboard: {
            title: "Dashboard",
            path: "/dashboard",
        },
    },
    auth: {
        login: {
            title: "Login",
            path: '/login',
        },
        register: {
            title: "Register",
            path: '/register',
        },
    },
    dashboard: {
        welcome: {
            title: trans('dashboard.getting_started'),
            path: "/welcome",
        },
        settings: {
            title: trans('dashboard.settings'),
            path: "/settings",
        },
        paymentMethods: {
            title: trans('dashboard.payment_methods'),
            path: "/payments",
        },
        paymentMethodsOrder: {
            title: trans('dashboard.order_payment_methods'),
            path: "/payments/order",
        },
        verificationMethods: {
            title: trans('dashboard.verification_methods'),
            path: "/verification-methods",
        },
        updatePassword: {
            title: trans('dashboard.update_password'),
            path: '/user/password',
        }
    },
};

export default pages;
