<template>
    <div class="flex w-full relative" @click.stop="toggleDropdown">
        <div class="w-full">
            <input
                class="flex w-full relative input-primary bg-gray-100 hover:border-buckarooGreen active:border-buckarooGreen focus:border-buckarooGreen focus-visible:border-buckarooGreen"
                :value="selectedOptions?.length === 0 ? 'Select' : selectedOptions.join(', ')"
                readonly
            />
        </div>
        <div
            v-if="dropdownOpen"
            class="absolute mt-12 left-0 w-full bg-gray-50 border border-buckarooGreen z-10 rounded-3xl"
        >
            <ul class="max-h-60 overflow-auto rounded-md py-1 text-base focus:outline-none sm:text-sm">
                <li
                    v-for="(option, index) in filteredOptions"
                    :key="index"
                    @click="toggleOption(option)"
                    class="flex gap-3 mb-3 py-3 px-5 hover:cursor-pointer transition-all rounded-2xl duration-300 hover:bg-buckarooGreen"
                >
                    <img
                        :src="
                            selectedValues?.includes(option?.key)
                                ? '/images/checked-checkbox.png'
                                : '/images/unchecked-checkbox.png'
                        "
                    />
                    <span class="block truncate">{{ option?.value }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';

const { options } = defineProps({
    options: {
        type: Array,
    },
});

const selectedValues = defineModel();

const dropdownOpen = ref(false);
const search = ref('');

const filteredOptions = computed(() => {
    if (!search.value) return options;
    return options.filter(option => option.value.toLowerCase().includes(search.value.toLowerCase()));
});

const selectedOptions = computed(() => {
    if (!selectedValues || !selectedValues.value) return [];
    return options.filter(option => selectedValues.value.includes(option.key)).map(option => option.value);
});

function toggleDropdown() {
    dropdownOpen.value = !dropdownOpen.value;
}

function toggleOption(option) {
    dropdownOpen.value = false;

    const index = selectedValues.value.indexOf(option.key);

    if (index > -1) {
        selectedValues.value.splice(index, 1);
    } else {
        selectedValues.value.push(option.key);
    }
}
</script>
