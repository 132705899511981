import { createApp } from 'vue';
import { i18nVue } from 'laravel-vue-i18n';

import.meta.glob([
  '../images/**',
]);

import Login from './pages/Auth/Login.vue'
import Register from './pages/Auth/Register.vue'
import Settings from './pages/Dashboard/Settings.vue'
import Welcome from './pages/Dashboard/Welcome.vue';
import PaymentMethods from './pages/Dashboard/PaymentMethods.vue';
import PaymentMethod from './pages/Dashboard/PaymentMethod.vue';
import PaymentMethodsOrder from './pages/Dashboard/PaymentMethodsOrder.vue';
import UpdatePassword from './pages/Dashboard/UpdatePassword.vue';
import ForgotPassword from './pages/Auth/ForgotPassword.vue'
import ResetPassword from './pages/Auth/ResetPassword.vue'
import Checkout from './pages/Checkout.vue'
import VerificationMethods from './pages/Dashboard/VerificationMethods.vue';
import MainMenu from './components/MainMenu.vue'
import UserSettings from './components/UserSettings.vue'
import Loading from './components/Loading.vue'
import AuthBlock from './components/AuthBlock.vue'


createApp({
    components: {
        Login,
        Register,
        Settings,
        Welcome,
        PaymentMethods,
        PaymentMethod,
        PaymentMethodsOrder,
        UpdatePassword,
        ForgotPassword,
        ResetPassword,
        Checkout,
        VerificationMethods,
        MainMenu,
        UserSettings,
        AuthBlock
    },
})
.use(i18nVue, {
    fallbackMissingTranslations: 'en',
    resolve: lang => import(`../../lang/php_${lang}.json`),
})
.component('Loading', Loading)
.mount('#app');
