import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

export default function useAlert(toastSettings = {}) {
    const defaultSettings = {
        position: 'top',
        duration: 5000,
        isClosable: true,
    }

    const alert = useToast({ ...defaultSettings, ...toastSettings });

    function success(message) {
        alert.success(message);
    }

    function error(message) {
        alert.error(message);
    }

    function info(message) {
        alert.info(message);
    }

    return {
        alert,
        success,
        error,
        info,
    }
}
