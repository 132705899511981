<template>
    <AuthBlock>
        <div class="space-y-8">
            <div class="space-y-2">
                <label class="text-md font-thin block pl-1">{{ $t('auth.email') }}</label>
                <input type="text" class="input-primary" :placeholder="$t('auth.email')" v-model="data.email" />
            </div>

            <div class="space-y-2">
                <label class="text-md font-thin block pl-1">{{ $t('auth.new_password') }}</label>
                <input
                    type="password"
                    class="input-primary"
                    :placeholder="$t('auth.new_password')"
                    v-model="data.password"
                />
            </div>

            <div class="space-y-2">
                <label class="text-md font-thin block pl-1">{{ $t('auth.password_confirmation') }}</label>
                <input
                    type="password"
                    class="input-primary"
                    :placeholder="$t('auth.password_confirmation')"
                    v-model="data.confirm_password"
                />
            </div>

            <loading v-if="loading" />
            <button v-else class="btn-primary font-bold w-full" @click="handleSubmit">
                {{ $t('auth.send') }}
            </button>

            <a href="/login" class="block text-primary text hover:font-bold hover:underline pt-5">
                {{ $t('auth.login') }}
            </a>
        </div>
    </AuthBlock>
</template>

<script>
import { onMounted } from 'vue';
import { useApi } from '@js/utils/api';
import useAlert from '@js/composables/useAlert.js';
import getUrlParams from '@js/utils/get-url-params.js';
import AuthBlock from '../../components/AuthBlock.vue';
import { trans } from 'laravel-vue-i18n';
import endpoints from '@js/utils/endpoints';

export default {
    components: {
        AuthBlock,
    },
    setup() {
        const { post, data, error, loading } = useApi(endpoints.auth.resetPassword, {
            email: '',
            password: '',
            confirm_password: '',
            token: '',
        });

        const { success, error: errorAlert } = useAlert();

        const setParamsFromURL = () => {
            const token = getUrlParams('token');
            const email = getUrlParams('email');

            if (!token || !email) {
                errorAlert(trans('alerts.invalid_reset_password_link'));
                return;
            }

            data.value.email = email;
            data.value.token = token;
        };

        const handleSubmit = () => {
            post({
                email: data.value.email,
                password: data.value.password,
                password_confirmation: data.value.confirm_password,
                token: data.value.token,
            })
                .then(() => {
                    success(data.value.message);
                    window.location.href = '/login';
                })
                .catch(() => {
                    if (error.value.response?.data?.message) return errorAlert(error.value.response.data.message);
                    errorAlert(trans('alerts.password_update_failed'));
                });
        };

        onMounted(() => setParamsFromURL());

        return {
            data,
            handleSubmit,
            loading,
        };
    },
};
</script>
