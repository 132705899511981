<template>
    <div class="p-5">
        <div class="divide-y bg-white rounded-2xl">
            <div class="rounded-t-2xl flex items-center justify-between p-5 bg-white">
                <div class="space-y-1">
                    <h1 class="text-2xl font-bold">{{ $t('dashboard.payments_methods_title') }}</h1>
                    <p class="text-gray-500 text-sm">{{ $t('dashboard.payment_methods_description') }}</p>
                </div>
            </div>

            <div class="px-5 py-10 grid xl:grid-cols-5 lg:grid-cols-3 grid-cols-2 gap-4">
                <PaymentCard
                    v-for="paymentMethod in paymentMethods"
                    :key="paymentMethod.name"
                    :payment="paymentMethod"
                    @openPaymentModal="$refs.paymentModal.openModal(paymentMethod)"
                />
            </div>
        </div>
    </div>

    <PaymentModal ref="paymentModal" />
</template>

<script setup>
import { ref } from 'vue';
import PaymentCard from '@js/components/PaymentCard.vue';
import PaymentModal from '@js/components/PaymentModal.vue';

const props = defineProps({
    paymentMethods: Array,
});

const paymentMethods = ref(props.paymentMethods);
</script>
