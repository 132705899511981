<template>
    <div class="flex flex-col rounded-xl bg-white items-center overflow-hidden p-8 gap-4 text-gray-500">
        <slot name="logo"></slot>
        <div class="flex flex-col justify-start w-full gap-2 mb-4">
            <div class="flex flex-col w-full">
                <label class="mb-1">IBAN</label>
                <input v-model="model.iBAN" type="text" class="input-primary" />
            </div>

            <div class="flex flex-col w-full">
                <label class="mb-1">BIC</label>
                <input v-model="model.BIC" type="text" class="input-primary" />
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script setup>
const model = defineModel();
</script>
