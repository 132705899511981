const endpoints = {
    auth: {
        csrf: '/sanctum/csrf-cookie',
        login: '/login',
        register: '/register',
        forgotPassword: '/forgot-password',
        resetPassword: '/reset-password',
        me: 'me',
    },
    dashboard: {
        configs: '/configs',
    },
    news: {
        latest: '/news/latest',
    },
    user: {
        updateEmail: '/user/email',
        updatePassword: '/user/password',
        logout: '/user/logout',
    },
    countries: {
        index: '/countries',
    }
};

export default endpoints;
