<template>
    <div class="flex space-x-3 h-full">
        <select
            v-model="selectedDate.day"
            class="bg-white border focus-visible:buckarooGreen focus-visible:border-buckarooGreen focus-visible:outline-buckarooGreen text-sm rounded-lg block w-full p-2.5"
        >
            <option :value="null">{{ $t('checkout.day') }}</option>
            <option v-for="d in days" :value="d">{{ dayjs().month(0).date(d).format('DD') }}</option>
        </select>
        <select
            v-model="selectedDate.month"
            class="bg-white border focus-visible:buckarooGreen focus-visible:border-buckarooGreen focus-visible:outline-buckarooGreen text-sm rounded-lg block w-full p-2.5"
        >
            <option :value="null">{{ $t('checkout.month') }}</option>
            <option v-for="m in months" :value="m">
                {{
                    dayjs()
                        .month(m - 1)
                        .format('MMM')
                }}
            </option>
        </select>
        <select
            v-model="selectedDate.year"
            class="bg-white border focus-visible:buckarooGreen focus-visible:border-buckarooGreen focus-visible:outline-buckarooGreen text-sm rounded-lg block w-full p-2.5"
        >
            <option :value="null">{{ $t('checkout.year') }}</option>
            <option
                v-for="y in years"
                :value="
                    dayjs()
                        .subtract(y - 1, 'year')
                        .format('YYYY')
                "
            >
                {{
                    dayjs()
                        .subtract(y - 1, 'year')
                        .format('YYYY')
                }}
            </option>
        </select>
    </div>
</template>

<script>
import { ref } from 'vue';
import dayjs from 'dayjs';

export default {
    name: 'Datepicker',
    props: ['modelValue'],
    watch: {
        selectedDate: {
            handler(value, oldValue) {
                this.setDateOptions();
            },
            deep: true,
        },
    },
    setup(props, { emit }) {
        const days = ref(31);
        const months = ref(12);
        const years = ref(100);

        const selectedDate = ref({
            day: null,
            month: null,
            year: null,
        });

        const setDateOptions = () => {
            let selectedDayjs = dayjs();

            if (selectedDate.value.day) {
                selectedDayjs = selectedDayjs.date(selectedDate.value.day);
            }

            if (selectedDate.value.month) {
                selectedDayjs = selectedDayjs.month(selectedDate.value.month - 1);
            }

            if (selectedDate.value.year) {
                selectedDayjs = selectedDayjs.year(selectedDate.value.year);
            }

            days.value = parseInt(selectedDayjs.endOf('month').format('D'));

            if (selectedDate.value.day && selectedDate.value.day > days.value) {
                selectedDate.value.day = null;
            }

            if (selectedDate.value.day && selectedDate.value.month && selectedDate.value.year) {
                emit(
                    'update:modelValue',
                    dayjs()
                        .date(selectedDate.value.day)
                        .month(selectedDate.value.month - 1)
                        .year(selectedDate.value.year)
                        .format('DD-MM-YYYY')
                );

                return;
            }

            emit('update:modelValue', null);
        };

        if (props.modelValue) {
            selectedDate.value = {
                day: dayjs(props.modelValue).date(),
                month: dayjs(props.modelValue).month() + 1,
                year: dayjs(props.modelValue).year(),
            };
        }

        return {
            days,
            months,
            years,
            selectedDate,
            dayjs,
            setDateOptions,
        };
    },
};
</script>
