<template>
    <div class="flex flex-col rounded-xl bg-white items-center overflow-hidden p-8 gap-4 text-gray-500">
        <slot name="logo"></slot>
        <div class="flex flex-col w-full mb-4">
            <label class="w-full mb-1">
                {{ $t('checkout.choose_gender') }}
            </label>
            <select v-model="model.gender" class="input-primary">
                <option v-for="option in genderOptions" :key="option.value" :value="option.value">
                    {{ option.label }}
                </option>
            </select>
        </div>

        <div class="flex justify-start w-full gap-2 mb-4">
            <input v-model="model.acceptTerms" type="checkbox" id="acceptTerms" class="w-4 h-4 accent-buckarooGreen" />
            <label for="acceptTerms" v-html="$t('checkout.klarna_terms')"></label>
        </div>

        <slot></slot>
    </div>
</template>

<script setup>
import { wTrans } from 'laravel-vue-i18n';

const model = defineModel();

const genderOptions = [
    { value: 'male', label: wTrans('checkout.male') },
    { value: 'female', label: wTrans('checkout.female') },
];
</script>
